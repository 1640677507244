<template>
  <div id="cart">
    <!-- 导航栏 -->
    <van-nav-bar title="购物车" left-text="返回" right-text="管理" left-arrow :fixed="true" @click-left="onClickLeft" @click-right="onClickRight" />

    <!-- 商品 -->
    <main v-if="cartlist.length > 0">
      <van-row v-for="item in cartlist" :key="item.id" type="flex" align="center" gutter="0">
        <van-col span="2" @click="selectItem(item.id)">
          <van-checkbox :value="selectIds.includes(item.id)"></van-checkbox>
        </van-col>
        <van-col span="22">
          <van-swipe-cell>
            <van-card class="vcd" :price="item.price" desc="描述信息" :title="item.name" :thumb="host + 'commodities/' + item.defaultPic">
              <template #footer>
                <van-stepper v-model="item.sellsStatus" theme="round" button-size="22" disable-input @change="changeStatus(item.id)" />
              </template>
            </van-card>
            <template #right>
              <van-button square type="danger" text="删除" class="delete-button" @click="romveItem(item.id)" />
            </template>
          </van-swipe-cell>
        </van-col>
      </van-row>
    </main>

    <!-- 没有商品时显示的页面 -->
    <van-empty v-else description="购物车空空如也">
      <van-button round type="danger" class="bottom-button" @click="$router.push({ path: '/home' })">去购买</van-button>
    </van-empty>

    <!-- 提交订单栏 -->
    <van-submit-bar :price="totalprice" button-text="提交订单" @submit="onSubmit">
      <van-checkbox v-model="checkAll">全选</van-checkbox>
      <template #tip>
        你的收货地址不支持同城送,
        <span>修改地址</span>
      </template>
    </van-submit-bar>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Cart',
  data() {
    return {
      selectIds: [],
      // cartlist: [],//购物车数据
      goodslist: [],
      host: 'http://yongmie.top/'
      // host: 'http://8.138.152.95/',
      // host: 'http://localhost:1231/'
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {},
    onSubmit() {},
    changeStatus(id) {
      // 传递id
      this.$store.commit('changeaddcartlist', id);

      // 当前的数据
      // const currentdata =  this.cartlist.find(item=>item.id===id);
      // console.log('currentdata',currentdata);

      // const currentsell = currentdata.sellsStatus;
      // console.log('currentsell',currentsell);
    },
    // 复选框
    selectItem(id) {
      // console.log('id', id);
      const idx = this.selectIds.indexOf(id); //存在返回索引值不存在返回-1
      // 第一种写法
      // if (idx >= 0) {
      //   // 存在就删除
      //   this.selectIds.splice(idx, 1)
      // } else {
      //   // 不存在就添加
      //   this.selectIds.push(id)
      // }

      // 第二种写法
      idx >= 0 ? this.selectIds.splice(idx, 1) : this.selectIds.push(id);
    },
    // 删除商品
    romveItem(id) {
      //过滤出item.id不等于传过来的id 在赋值给this.cartlist
      // this.cartlist = this.cartlist.filter(item => item.id !== id);

      this.$store.commit('removeaddcartlist', id);
    }
  },
  computed: {
    // 全选,
    checkAll: {
      get() {
        //
        return this.cartlist.every((item) => this.selectIds.includes(item.id));
      },
      set(nv) {
        // console.log('nv', nv);
        // 第一种写法
        // if (v) {
        //   this.selectIds = this.cartlist.map(item => item.id)
        // } else {
        //   this.selectIds = []
        // }

        // 第二种写法
        this.selectIds = nv ? (this.selectIds = this.cartlist.map((item) => item.id)) : [];
      }
    },
    // 计算总价格
    totalprice() {
      // purchaseNums
      return (
        this.cartlist
          .filter((item) => this.selectIds.includes(item.id)) //过滤出勾选的商品
          .reduce((pre, item) => pre + item.price * item.sellsStatus, 0) * 100
      ); //然后再计算总价格
    }

    //辅助函数mapState
    // ...mapState({
    //   cartlist: (state) => state.addcartlist
    // })
  },
  created() {
    //一进页面把App van-tabbar 隐藏
    this.$parent.showMenu = false;

    // console.log('store',this.$store.state.addcartlist);
    const addcartlist = this.$store.state.addcartlist;
    this.cartlist = addcartlist;

    // 读取本地存储购物车信息
    // let addcartlist = localStorage.getItem('Cartlist');//[],[{}],null,xxx
    // try {
    //   addcartlist = JSON.parse(addcartlist)||[]
    // } catch (error) {
    //     addcartlist=[]
    // }
    // this.cartlist = addcartlist;
  },
  destroyed() {
    //离开页面把App van-tabbar 显示
    this.$parent.showMenu = true;
  }
};
</script>

<style scoped lang="scss">
#cart {
  padding-top: 50px;
  padding-bottom: 100px;

  .delete-button {
    height: 100%;
  }

  main {
    padding-left: 16px;

    .vcd {
      background-color: #fff;
    }
  }
}
</style>
